module.exports = [{
      plugin: require('/opt/build/repo/node_modules/@talves/gatsby-plugin-netlify-cms/gatsby-browser'),
      options: {"plugins":[],"modulePath":"/opt/build/repo/src/cms","manualInit":true,"enableIdentityWidget":false,"htmlTitle":"Netlify CMS"},
    },{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-google-analytics/gatsby-browser'),
      options: {"plugins":[],"trackingId":"UA-43490273-1"},
    },{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-offline/gatsby-browser'),
      options: {"plugins":[]},
    },{
      plugin: require('/opt/build/repo/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
