/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
import React from 'react'
import Root from './src/Root'

require("./src/assets/css/prismjs-themes/default.css")

export const wrapRootElement = ({ element }) => (<Root>{element}</Root>)
