import React from 'react'
import withRoot from './withRoot'

const WithRoot = withRoot(props => props.children)

const Root = ({ children }) => {
  return (<WithRoot>{children}</WithRoot>)
}

export default Root
