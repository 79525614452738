/* eslint-disable no-underscore-dangle */
/**
 * Based on: https://github.com/mui-org/material-ui/tree/master/examples/gatsby
 */

import { SheetsRegistry } from 'jss';
import { createMuiTheme, createGenerateClassName } from '@material-ui/core/styles';

// A theme with custom primary and secondary color.
// It's optional.
const theme = createMuiTheme({
  palette: {
    primary: {
      // light: purple[300],
      main: "#5C7A94",
      // dark: purple[700],
    },
    secondary: {
      // light: green[300],
      main: "#2e2520",
      // dark: green[700],
    },
    hero: {
      light: "#e8e8e8",
      main: "#cccccc",
      dark: "#8e8e8e",
    },

  },
  typography: {
    useNextVariants: true,
    // default font Family "Roboto", "Helvetica", "Arial", sans-serif
    // fontFamily: [
    //   '"Roboto"',
    //   '"Helvetica"',
    //   '"Helvetica Neue"',
    //   '"Arial"',
    //   'sans-serif',
    // ].join(','),
    fontFamilySecond: [
      // '"Alex Brush"',
      '"Chivo"',
      '"Helvetica"',
      '"Helvetica Neue"',
      '"Arial"',
      'sans-serif',
    ].join(','),
  },
});

function createPageContext() {
  return {
    theme,
    // This is needed in order to deduplicate the injection of CSS in the page.
    sheetsManager: new Map(),
    // This is needed in order to inject the critical CSS.
    sheetsRegistry: new SheetsRegistry(),
    // The standard class name generator.
    generateClassName: createGenerateClassName(),
  };
}

export default function getPageContext() {
  // Make sure to create a new context for every server-side request so that data
  // isn't shared between connections (which would be bad).
  if (!process.browser) {
    return createPageContext();
  }

  // Reuse context on the client-side.
  if (!global.__INIT_MATERIAL_UI__) {
    global.__INIT_MATERIAL_UI__ = createPageContext();
  }

  return global.__INIT_MATERIAL_UI__;
}
